*{
    border: 0;
    padding: 0;
}
.s_cont{
    display: flex;
    flex-direction: row;
}
.img-div{
    width: 45%;
    height: 100%;
}
.btn-div{
    /* background-image: "/images/ba-ghost2.jpg"; */
    text-align: center;
    align-items: center;
    width: 45%;
    height: 100%;
}
button{

    width: 100px;
    height: 50px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(182, 182, 182);
}
button:hover{
    background-color: rgb(93, 72, 77);
    color: rgb(250, 250, 250);
}
#c_img{
    width: 90%;
    height: 100vh;
}