.site-container{
  position: absolute;
  left: 30%;
}
html {
  font-size: 14px;
  line-height: 1.5;
  color: black;
  margin:0;
}
body {
  line-height: 1.5;
  color: black;
  margin:0;
}
img{
  height: 300px;
  width:500px;
}
.heading img:nth-child(1){
  height: 80px;
  width: 250px;
}
.heading img:nth-child(2){
  margin-left:370px;
  height: 40px;
  width: 60px;
}
.heading{
  display: flex;
  color: green;
  border-bottom: 2px solid green;
}
.txtHeader {
  position: absolute;
  margin-left: 60px;
  font-size: 56px;
  color: #33c4ed;
}
.boxs{
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(204, 202, 202);
  box-shadow: 0px 2px 10px 2px rgb(216, 214, 214);
}
.top{
  display:flex;
  margin:0;
  padding: 0;
  border-bottom:1px solid black
}
.mid{
  padding:7px 0 0 10px;
  display:flex
}
.dots{
  padding-right: 5px;
}
.dots img{
  padding-top: 15px;
}
.footer{
  display:flex;
  padding-left:12px;
  flex-direction:column;
} 